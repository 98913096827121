import Vue from 'vue'
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'

Vue.component('preorder-form-modal', {
  data() {
    return {
      show: false,
      preorder: {
        name: '',
        phone: '',
        email: '',
        product_offer_id: null
      },
      errors: {}
    }
  },

  mounted() {
    this.$root.$on('openPreorderModalForm', (productOfferId) => {
      this.open(productOfferId);
    });
  },

  components: {
    'vue-recaptcha': VueRecaptcha
  },

  methods: {
    open(productOfferId) {
      this.preorder.product_offer_id = productOfferId
      this.show = true
    },

    close() {
      this.show = false
    },

    submit() {
      /* 
      if (!this.agree) { 
        this.agree_error = 'Нужно согласиться на обработку персональных даннных'
        return
      }
      */

      if (this.captcha_token == '') {
        alert('Вы не разгадали капчу')
        return
      }

      axios.post('/api/preorders', {
        preorder: this.preorder,
        captcha_token: this.captcha_token
      }).then((response) => {
        this.show = false
        this.preorder = {
          name: '',
          phone: '',
          email: '',
          product_offer_id: null
        }
        alert('Ваша зявка успешно отправлена!\nНаши операторы свяжутся с вами в ближайшее время')
      }).catch((errors) => {
        this.errors = errors.response.data.errors
        this.resetRecaptcha()
      })
    },

    onVerify: function (response) {
      this.captcha_token = response
    },

    onExpired: function () {
      this.captcha_token = ''
    },

    resetRecaptcha () {
      this.$refs.recaptcha.reset() // Direct call reset method
    }
  },

  template: `
    <div v-if="show" class="popup-wrapper">
        <div class="popup-wrapper__container">
            <div class="popup-wrapper__content">
                <div class="popup-close" @click="close()"><svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg></div>
                <div class="popup popup_reviews">
                    <div class="popup__heading">
                        Оформить предзаказ
                    </div>
                    
                    <form class="review-form-add" @submit.prevent="submit()">
                        <div class="review-form-add__form-data form-data" style="padding-right: 80px;">
                            <div class="form-field">
                                <label class="custom-label">Ваше имя</label>
                                <input type="text" 
                                class="custom-input theme-default" 
                                v-model="preorder.name"
                                required="true">
                                <span v-for="error in errors.name" style="color: red;">{{error}}</span>
                            </div>

                            <div class="form-field">
                                <label class="custom-label">Контактный телефон</label>
                                <input type="text" 
                                class="custom-input theme-default" 
                                v-model="preorder.phone"
                                required="true">
                                <span v-for="error in errors.phone" style="color: red;">{{error}}</span>
                            </div>

                            <div class="form-field">
                                <label class="custom-label">eMail</label>
                                <input type="text" 
                                class="custom-input theme-default" 
                                v-model="preorder.email"
                                required="true">
                                <span v-for="error in errors.email" style="color: red;">{{error}}</span>
                            </div>


                            <div class="form-field-row form-field-row_valign-bottom">
                                <vue-recaptcha 
                                    sitekey="6LdCUzMcAAAAAFKeD3P-_0tL5YGSvOGf0QOycLH4"
                                    ref="recaptcha"
                                    @verify="onVerify"
                                    @expired="onExpired"></vue-recaptcha>
                            </div>

                            <div class="form-field-row form-field-row_valign-bottom">
                                <button 
                                    class="button button_theme_default button_size_m"
                                    style="margin-left: 0;">Отправить</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  `
})
