//Vue
import Vue from 'vue';
import axios from 'axios';
import Pluralizer from './services/pluralizer.js'
import ServcieForm from './vue-components/service_form.vue'
import PreorderForm from './vue-components/preorder_form_modal.js'
const token = document.querySelector('meta[name="csrf-token"]')

if (token) { 
    axios.defaults.headers.common['X-CSRF-Token'] = token.getAttribute('content');
}

import productAccssories from './vue-components/product_accessories.vue';
import newFeedback from './vue-components/new_feedback.vue';
import tabs from './vue-components/tabs';

import productElementFixedCard from './vue-components/productElementFixedCard';
import sliderFilterTypes from './vue-components/sliderFilterTypes';

import searchWidget from './vue-components/searchWidget';
import cityLocation from './vue-components/cityLocation';
import m_modalAllCity from './vue-components/m_modalAllCity';
import m_modalSignIn from './vue-components/m_signIn';
import m_modalSignUp from './vue-components/m_signUp';

import modalProductPreviewGallery from './vue-components/modalProductPreviewGallery';
import modalAddCart from './vue-components/modalAddCart';
import modalAllCity from './vue-components/modalAllCity';
import m_modalFullDescription from './vue-components/m_modalFullDescription';
import m_mobilePanel from './vue-components/m_mobilePanel';
import m_modalComponent from './vue-components/m-modal';
import m_modalProductColors from './vue-components/m_modalProductColors';
import m_modalCatalogNavigation from './vue-components/m_modalCatalogNavigation';
import m_modalCatalogNavigationRoot from './vue-components/m_modalCatalogNavigationRoot';
import m_modalLkNavigationRoot from './vue-components/m_modalLkNavigationRoot';
import m_modalMobileSearch from './vue-components/m_modalMobileSearch';
import m_productDetailDescription from './vue-components/m_productDetailDescription';
import m_productDetailSertificates from './vue-components/m_productDetailSertificates';

import mModalPickup from './vue-components/mobile-modals/pickup';
import mModalFinder from './vue-components/mobile-modals/finder';
import mModalVideo from './vue-components/mobile-modals/video';

import filterDesktop from './vue-components/catalog/filterDesktop';
import filterMobile from './vue-components/catalog/filterMobile';

import cart from './vue-components/cart/cart';

// import orderInfoWidget from './vue-components/order/orderInfoWidget';
//
// import order from './vue-components/order/order';
// import orderSuccess from './vue-components/order/order-success';
// import orderList from './vue-components/order/order-list';
// import modalOrderDetail from './vue-components/order/modalOrderDetail';
// import modalOrderFeedback from './vue-components/order/modalOrderFeedback';
// import m_modalOrderFeedback from './vue-components/order/m_modalOrderFeedback';

//product-reviews
import m_modalProductReviews from './vue-components/product-reviews/m_modalProductReviews';
import m_productReview from './vue-components/product-reviews/m_productReview';
import m_modalReviewRate from './vue-components/product-reviews/m_modalReviewRate';
import m_modalReviewForm from './vue-components/product-reviews/m_modalReviewForm';

import modalProductReviews from './vue-components/product-reviews/modalProductReviews';
import productReview from './vue-components/product-reviews/productReview';
import modalReviewForm from './vue-components/product-reviews/modalReviewForm';

import questionFrom from './vue-components/questionForm'
import feedbackForm from './vue-components/feedbackForm'

import productsList from './vue-components/catalog/productsList.vue'
import feedbackModal from './vue-components/feedback_modal.js'
import welcomeProducts from './vue-components/welcome_products.vue'
import newOrder from './vue-components/new_order.vue'

const app = new Vue({
    delimiters: ['${', '}'],
    el: '#app',
    components: {
        "products-list": productsList,
        "welcome-products": welcomeProducts,
        "product-accessories": productAccssories,
        "new-order": newOrder,
        "service-form": ServcieForm,
        "new-feedback": newFeedback
    },
    data: {
        openNavStep: false,

        articulProduct: false,

        offerActive: false,
        offerActiveColor: false,

        showModal: false,
        showModalPictureGallery: false,
        showModalReviewForm: false,
        productsPage: 1,
        compareProductsIds: [],
        favoriteIds: [],
        cart: {
            line_items: []
        }
    },
    mounted() {
        this.compareProductsIds = gon.compare_ids
        this.favoriteIds = gon.favorite_ids
        this.cart = gon.cart
    },
    methods: {
        openMobileCatalog() {
            this.$root.$emit('openMobileCatalog')
        },
        openMobileLK(){
            this.$root.$emit('openMobileLK')
        },
        openMobileFullDescription(){
            this.$root.$emit('openMobileFullDescription')
        },
        openMobileSearch(){
            this.$root.$emit('openMobileSearch')
        },
        m_userSignOut() {
            axios.get('/users/sign_out').then((response) => {
                window.location.reload()
            })
        },
        addToFavorite(productId) {
            axios.post('/favorites', {product_id: productId}).then((response) => {
                this.favoriteIds.push(productId)
            })
        },
        removeFromFavorite(productId) {
            console.log('remove from favorite')
            axios.delete(`/favorites/${productId}`).then((response) => {
                for (let i = 0; i < this.favoriteIds.length; i++) {
                    if (this.favoriteIds[i] == productId) {
                        this.favoriteIds.splice(i, 1)
                    }
                }
            })
        },
        isAddToCart(productOfferId) {
            if (this.cart.line_items.length <= 0) { return false }

            for (let i = 0; i < this.cart.line_items.length; i++) {
                if (this.cart.line_items[i].product_offer_id == productOfferId) { return true }
            }

            return false
        },
        isFavorite(productId) {
            if (this.favoriteIds.length <= 0) { return false }

            for (let i = 0; i < this.favoriteIds.length; i++) {
                if (this.favoriteIds[i] == productId) { return true }
            }

            return false
        },
        removeFromCompare(productId) {
            axios.delete(`/compares/${productId}`).then((response) => {
                window.location.reload()
            })
        },
        clearCompare() {
            axios.delete(`/compares/clear`).then((response) => {
                window.location.pathname = '/compares'
            })
        },
        addToCompare(productId) {
            axios.post('/compares', { 
                product_id: productId
            }).then((response) => {
                this.compareProductsIds.push(productId)
            }).catch((error) => {
                console.log(error)
            })
        },
        compareIsInclide(productId) {
            if (this.compareProductsIds.length <= 0) { return false }

            for (let i = 0; i < this.compareProductsIds.length; i++) {
                if (this.compareProductsIds[i] == productId) { return true }
            }

            return false
        },
        showModalInit(idPopup) {
            /*
            *
            * Метод открытия модального окна, который вызывает
            * метод render дочернего компонента modalComponent
            * */
            let _this = this;

            _this.showModal = idPopup;

            _this.$nextTick(function () {
                _this.$refs.modal.render();
            });
        },
        showModalProductPreviewGalleryInit(id) {
            /*
            *
            * Метод открытия модального окна, который вызывает
            * метод render дочернего компонента modalProductPreviewGallery
            * */
            let _this = this;

            _this.showModalPictureGallery = true;

            _this.$nextTick(function () {
                _this.$refs.modalGallery.render(id);
            });
        },
        showModalReviewsInit() {
            /*
            *
            * Метод открытия модального окна из компонента modalProductReviews
            * */
            this.$root.$emit('openReviews');
        },
        showModalReviewFormInit() {
            this.$root.$emit('openReviewForm');
        },
        openFeedbackModal() {
            this.$root.$emit('openFeedbackModalForm')
        },

        openPreorderModal(productOfferId) {
            console.log(productOfferId)
            this.$root.$emit('openPreorderModalForm', productOfferId)
        },

        showModalDeliveryInit(section) {
            if (window.matchMedia('screen and (min-width: 1200px)').matches) {
                this.$root.$emit('openModalDelivery', section);
            } else {

                if(section === 'delivery') {
                    this.$root.$emit('m_openModalDelivery');
                } else if(section === 'pickup') {
                    this.$root.$emit('m_openModalPickup');
                } else if(section === 'finder') {
                    this.$root.$emit('m_openModalFinder');
                }

            }
        },
        openModalVideo(e) {
            /*
            *
            * Метод открытия мобильной панели из компонента openModalVideo
            * 

            if (window.innerWidth > 1024) {
                this.$root.$emit('openModalVideo');
            } else {
                const iframe = e.currentTarget.nextElementSibling;
                iframe.setAttribute('src', iframe.dataset.src);
                e.currentTarget.parentNode.classList.add('is-play');
            }

            */

            const iframe = e.currentTarget.nextElementSibling;
            iframe.setAttribute('src', iframe.dataset.src);
            e.currentTarget.parentNode.classList.add('is-play');
        },
        m_openMobilePanelInit() {
            /*
            *
            * Метод открытия мобильной панели из компонента m_mobilePanel
            * */

            this.$root.$emit('m_openMobilePanel');
        },
        m_showModalReviewsInit() {
            /*
            *
            * Метод открытия модального окна из компонента m_modalProductReviews
            * */

            this.$root.$emit('m_openReviews');
        },
        m_showModalColorsInit(color) {
            /*
            *
            * Метод открытия модального окна из компонента m_modalProductColors
            * */

            this.$root.$emit('m_openProductColors');
        },
        m_showCatalogNavigationInit(category_id, parent_category_title) {
            /*
            *
            * Метод открытия модального окна из компонента m_modalCatalogNavigation
            * */

            this.$root.$emit('changeModalCatalog', category_id, parent_category_title);
        },
        showModalAllCityInit() {
            /*
            *
            * Метод открытия модального окна из компонента m_modalAllCity
            * */

            this.$root.$emit('openAllCity');
        },
        m_showModalAllCityInit() {
            /*
            *
            * Метод открытия модального окна из компонента m_modalAllCity
            * */

            this.$root.$emit('m_openAllCity');
        },
        m_showModalSignIn() {
            /*
            *
            * Метод открытия модального окна из компонента m_modalSignIn
            * */

            this.$root.$emit('m_openSignIn');
        },
        m_showModalSignUp() {
            /*
            *
            * Метод открытия модального окна из компонента m_modalSignUp
            * */

            this.$root.$emit('m_openSignUp');
        },
        m_openFilterMobileInit() {
            /*
            *
            * Метод открытия мобильного фильтра из компонента filterMobile
            * */

            this.$root.$emit('m_openFilterMobile');
        },
        showModalAddCartInit(offerId) {
            /*
            *
            * Метод открытия модального окна, который вызывает
            * метод render дочернего компонента modalAddCart
            * */
            this.$root.$emit('openAddCart', offerId);
            this.cart.line_items.push({ product_id: offerId })
        },
        openCityLocationPanelInit() {
            console.log('click!')
            /*
            *
            * Метод открытия мобильной панели из компонента cityLocation
            * */

            this.$root.$emit('openCityLocationPanel');
        },
        closeModal() {
            let _this = this,
                html = document.querySelector('html');

            _this.showModal = false;
            _this.showModalPictureGallery = false;

            html.classList.remove('is-open-modal');

            setTimeout(function () {
                html.classList.remove('is-modal-animated');
            }, 1000);
        },
        m_closeModal() {
            let _this = this,
                html = document.querySelector('html');

            _this.showModal = false;

            html.classList.remove('is-open-modal');
            html.dataset.animation = 'slideInLeft';

            setTimeout(function () {
                html.removeAttribute('data-animation');
            }, 1000);
        },

        offerInit(offer) {
            this.offerActive = offer;
            this.offerActiveColor = offer;
        },

        changeProductColor(slug) {
            let splitedOldUrl = window.location.pathname.split('/')
            splitedOldUrl[2] = slug
            let newUrl = splitedOldUrl.join('/')
            window.location = newUrl        
        },

        loadNextProductPage(e) {
            var url = e.target.getAttribute('href')
            
            axios.get(url).then(function(response) {
                console.log(response.data)
            }).catch(function(error) {

            })
        },

        addOrderToCatalog(order) {
            this.$root.$emit('addOrderToCatalog', order)
        }
    },
})

window.app = app;

if (window.offerColor) {
    console.log(window.offerColor)
    app.offerInit(window.offerColor)
}

//Vendors
import './vendors/jquery.nice-select.min.js';

// JS Components
import picturePreviewSlider from './components/picturePreviewSlider';
import picturePreviewSlider2 from './components/picturePreviewSlider2';
import pictureProductDetailSlider from './components/pictureProductDetailSlider';
import catalogSectionNavigation from './components/catalogSectionNavigation';
import selectBreadcrumbs from './components/selectBreadcrumbs';
import selectCustom from './components/selectCustom';
import selectSortProductsCatalog from './components/selectSortProductsCatalog';
import sectionDropped from './components/sectionDropped';
import accessorizeSlider from './components/accessorizeSlider';
import actionsPromoSlider from './components/actionsPromoSlider';
import compilationsSlider from './components/compilationsSlider';
import promoProductSlider from './components/promoProductSlider';
import fixedBox from './components/fixedBox';
import anchorNavigation from './components/anchorNavigation';
import video from './components/video';
import compareSlider from './components/compareSlider';
import bannersSlider from './components/bannersSlider';
import articlesSlider from './components/articlesSlider';
import instSlider from './components/instSlider';
import videoPreviewSlider from './components/videoPreviewSlider';
import scrollAnimated from './components/scrollAnimated';
import tabsSlider from './components/tabsSlider';
import lazyImages from './components/lazy';
import hideable from './components/hideable';
import crashTest from './components/crashTest';
import finder from './components/finder';
import shop from './components/shop';
import map from './components/map';
import delivery from './components/delivery';
import article from './components/article';
import compare from './components/compare';
import catalog from './components/catalog';

picturePreviewSlider();
picturePreviewSlider2();
pictureProductDetailSlider();
catalogSectionNavigation();
selectBreadcrumbs();
selectCustom();
selectSortProductsCatalog();
sectionDropped();
actionsPromoSlider();
compilationsSlider();

fixedBox();
anchorNavigation();
video();
compareSlider();
bannersSlider();
instSlider();
articlesSlider();
videoPreviewSlider();
scrollAnimated();
tabsSlider();
lazyImages();
hideable();
crashTest();
finder();
shop();
map();
delivery();
article();
compare();
catalog();
function initMaps() {
    if (gon.delivery_zones) {
        ymaps.ready(init);

        let current_city_coordinates = eval(`[${gon.current_city.coordinates}]`)

        function init(){
            for (var i = 0; i < gon.delivery_zones.length; i++) {
                let currentZoom

                if (i < 3) {
                    currentZoom = 9
                } else if (i < 5) {
                    currentZoom = 8
                } else {
                    currentZoom = 7
                }


                var myMap = new ymaps.Map(`map-${ i + 1 }`, {
                    center: current_city_coordinates,
                    zoom: currentZoom,
                    controls: ['searchControl']
                });

                var polygonCoordinates = eval(`[${gon.delivery_zones[i].data}]`)

                var myPolygon = new ymaps.Polygon(polygonCoordinates, {
                    hintContent: gon.delivery_zones[i].title
                }, {
                    fillColor: '#4d905a',
                    strokeColor: '#000',
                    opacity: 0.6,
                    strokeWidth: 3,
                    strokeStyle: 'shortdash'
                });

                myMap.geoObjects.add(myPolygon);
            }
        }
    }
}

window.initMaps = initMaps

setTimeout(function() {
    initMaps()
    accessorizeSlider()
}, 3000) 