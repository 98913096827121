<template>
  <div class="product-promo-slider product-promo-slider_tabs">
    <div class="product-promo-slider__slider js-product-promo-slider">
      <div class="product-promo-slider__slider-container swiper-container">
        <div class="product-promo-slider__slider-wrapper swiper-wrapper">
          <div class="product-promo-slider__slider-slide swiper-slide" v-for="product in products">
            <product-preview     
            :key="product.id" 
            :product="product"
            :remove_grid="remove_grid"
            :favorite="isFavorite(product.id)"
            :compared="isCompared(product.id)"></product-preview>
          </div>
        </div>
        <div class="product-promo-slider__arrows swiper-arrows">
          <div class="swiper-arrow-circle swiper-arrow-circle_prev swiper-button-prev">
              <svg width="16" height="12" class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
          </div>
          <div class="swiper-arrow-circle swiper-arrow-circle_next swiper-button-next">
              <svg width="16" height="12" class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deserialize } from 'deserialize-json-api';
import axios from 'axios'
import productPreview from "../vue-components/catalog/productPreview.vue"
import promoProductSlider from '../components/promoProductSlider';

export default {
  props: ['api_url', 'remove_grid'],
  data() {
    return {
      products: [],
      favoriteIds: [],
      compareIds: []
    }
  },
  mounted() {
    this.favoriteIds = gon.favorite_ids
    this.compareIds = gon.compare_ids
    if (this.api_url != undefined) {
      this.loadProducts(this.api_url)
    } else {
      this.loadProducts('/api/user_friendly_products.json')
    }
  },
  methods: {
    loadProducts(url) {
      axios.get(url)
        .then((response) => {
          this.products = deserialize(response.data).data
          setTimeout(() => {
            promoProductSlider()
          }, 500)
        })
        .catch((error) => {})
    },

    isFavorite(productId) {
      for (let i = 0; i <= this.favoriteIds.length; i++) {
        if (this.favoriteIds[i] == productId) { return true }
      }

      return false
    },

    isCompared(productId) {
      for (let i = 0; i <= this.compareIds.length; i++) {
        if (this.compareIds[i] == productId) { return true }
      }

      return false
    }
  },
  components: {
    "product-preview": productPreview
  }
}
</script>