<template>
  <div class="product" v-bind:class="{ 'is-add-favorite': favorite, 'is-add-cart': addedToCart, 'catalog__grid-item': grid }">
    <div class="product__inner">
      <div class="product__badges">
        <div class="product__badges-item product-rating-badge" v-if="product.reviews_count > 0">
          <span class="product-rating-badge__mark">{{ product.avg_rate }}</span>
          <span class="product-rating-badge__reviews">{{ product.reviews_count }} {{ pluralizedReviewCount }}</span>
        </div>

        <div class="product__badges-item product-tag" v-if="product.stock">Акция</div>
        <div class="product__badges-item product-tag pink" v-if="product.status_new">Новинка</div>
        <div class="product__badges-item product-tag md-hide" v-if="showOldPrice">-{{ differenceInPercent }}%</div>
      </div>
      <a v-if="favorite" href="#" class="product__favorite product-favorite" @click.prevent="removeFromFavorites()" style="position: absolute;">
        <svg class="icon icon-heart_fill">
          <use xlink:href="#icon-heart_fill"></use>
        </svg>
      </a>
      <a v-else href="#" class="product__favorite product-favorite" @click.prevent="addToFavorites(product.id)">
        <svg class="icon icon-heart_fill">
          <use xlink:href="#icon-heart_fill"></use>
        </svg>
      </a>

      <div class="product__picture">
        <preview-carousel v-for="(offer, index) in product.product_offers"
                          :key="offer.id"
                          :product="product" 
                          :currentOffer="offer" 
                          v-on:changeOffer="changeOffer"
                          v-if="isCurrentOffer(index, offer)">
          
        </preview-carousel>
        <a href="/cart" class="product__button-cart button button_theme_default button_size_m_upper">В корзине</a>
      </div>


      <div class="product__info">
        <div class="product__subcategory"><!-- {{ currentOffer.category.title }}--></div>

        <a :href="'/products/' + currentOffer.slug" class="product__name">
          <span>{{ currentOffer.title }}</span>
        </a>

        <div class="product__colors md-hide" style="font-size: 12px;">Кол-во расцветок: {{ product.product_offers.length }}</div>

        <div class="product__prices">
          <div class="product__price">{{ formattedCurrentPrice }} ₽</div>
          <div v-if="showOldPrice" class="product__price product__price_old">{{ formattedOldPrice }}₽</div>
          <div v-if="showOldPrice" class="product__percent product-tag">-{{ differenceInPercent }}%</div>
        </div>
      </div>

      <!-- Доступное кол-во цветов -->
      <div class="product__availability" v-if="product.product_offers.length > 1">
        <span class="product__availability-color"></span>Доступно цветов: {{ product.product_offers.length }}
      </div>

      
      <div class="product__hidden-info">
        <div class="product__controls">
            <button type="button" 
                    class="button button_theme_default button_size_m" 
                    v-if="addedToCart"
                    @click="window.location.replace('/cart')">
              В корзину
            </button>

            <button type="button" 
                    class="button button_theme_default button_size_m"
                    v-else-if="currentOffer.available_status == 'available'"
                    @click="addToCart()">
              Купить
            </button>

            <button type="button" 
                    class="button button_theme_default button_size_m"
                    v-else-if="currentOffer.available_status == 'available_for_order'"
                    @click="addToCart()">
              Предзаказ
            </button>

            <button type="button" 
                    class="button button_theme_default button_size_m"
                    v-else-if="currentOffer.available_status == 'only_salon'">
              Только в салоне
            </button>
          
            <button type="button" 
                    class="button button_theme_default button_size_m"
                    v-else>
              Нет в наличии
            </button>

          <button v-if="compared" type="button" class="button button_type_text_icon" @click="toCompares">
            <svg width="14" height="14" class="icon icon-compare"><use xlink:href="#icon-compare"></use></svg>В сравнение
          </button>

          <button v-else type="button" class="button button_type_text_icon" @click="addToCompare(product.id)">
            <svg width="14" height="14" class="icon icon-compare"><use xlink:href="#icon-compare"></use></svg>К сравнению
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import PreviewCarousel from './preview_carousel.vue'
import picturePreviewSlider from '../../components/picturePreviewSlider'
import Pluralizer from '../../services/pluralizer'
import Price from '../../services/price'

export default {
  props: ['product', 'favorite', 'compared', 'remove_grid'],

  data() {
    return {
      currentOfferId: 0,
      inCart: false
    }
  },

  computed: {
    showOldPrice() {
      if (!this.currentOffer.old_price) { return false }
      if (this.currentOffer.old_price > this.currentPrice) { return true }

      return false
    },

    formattedCurrentPrice() {
      return Price.format(this.currentPrice)
    },

    formattedOldPrice() {
      return Price.format(this.currentOffer.old_price)
    },

    grid() {
      if (this.remove_grid == 'true') {
        return false
      } else {
        return true
      }
    },

    differenceInPercent() {
      if (this.currentOffer.old_price && this.currentOffer.price < this.currentOffer.old_price) {
        return Price.differencePricesInPercents(this.currentOffer.price, this.currentOffer.old_price)
      }
    },

    currentPrice() {
      let role = gon.current_user.role

      switch (role) {
        case 'saler':
          return this.currentOffer.opt_price
          break
        case 'partner':
          return this.currentOffer.opt_price_partner
          break
        default:
          return this.currentOffer.price
      } 
    },

    pluralizedReviewCount() {
      let result = new Pluralizer(['отзыв', 'отзыва', 'отзывов'], this.product.reviews_count).call()
      return result
    },

    addedToCart() {
      for (let i = 0; i <= gon.cart_offers.length; i++) {
        if (gon.cart_offers[i] == this.currentOffer.id) { this.inCart = true }
      }

      return this.inCart
    },

    firstImage() {
      return this.currentOffer.product_images[0].image.thumb.url
    },

    currentOffer() {
      return this.product.product_offers[this.currentOfferId]
    }
  },

  methods: {
    addToCart() {
      this.$root.showModalAddCartInit(this.currentOffer.id)
      this.inCart = true
    },

    preorder() {
      this.$root.openPreorderModal(this.currentOffer.id)
    },

    isCurrentOffer(index, offer) {
      if (this.product.product_offers[this.currentOfferId] == offer) { return true }
      return false
    },

    addToCompare(product_id) {
      axios.post('/compares', {
        product_id: product_id
      }).then((response) => {
        this.compared = true
      }).catch((error) => {})
    },

    removeFromCompare() {
      axios.delete(`/compares/${this.product.id}`).then((response) => {
        this.compared = false
      }).catch((error) => {})
    },

    toCompares() {
      window.location.pathname = '/compares'
    },

    addToFavorites(product_id) {
      axios.post('/favorites', {
        product_id: product_id
      }).then((response) => {
        this.favorite = true
      })
    },

    removeFromFavorites() {
      axios.delete(`/favorites/${this.product.id}`).then((response) => {
        this.favorite = false
      }).catch((error) => {})
    },

    changeOffer(index) {
      if (this.currentOfferId == index) { return }
        
      this.currentOfferId = index
      let productOfferSelector = `[data-product-offer-id='${this.currentOffer.id}']`

      setTimeout(function() {
        picturePreviewSlider(productOfferSelector)
      }, 400)
    }
  },

  components: {
    'preview-carousel': PreviewCarousel
  }
}
</script>