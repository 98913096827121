import Vue from 'vue';
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';
import IMask from 'imask';

Vue.component('question-form', {
  props: ['image'],

  data() {
    return {
      captcha_token: '',
      question: {
        phone: ''
      },
      errors: {},
      accept: false
    }
  },

  components: {
    'vue-recaptcha': VueRecaptcha
  },

  mounted() {
    let phoneInput = document.querySelector('[name=PHONE_FEEDBACK_QUESTION]')

    IMask(phoneInput, {
      mask: '+{7} (000) 000-00-00 ',
      lazy: false,  // make placeholder always visible
    });
  },

  methods: {
    sendForm() {
      if (!this.accept) { 
        this.$set(this.errors, 'base', ['Нужно подтвердить согласие на обработку данных'])
        return 
      }

      if (this.captcha_token == '') {
        alert('Вы не разгадали капчу')
        return
      }
      
      axios.post('/questions', { 
        question: this.question,
        captcha_token: this.captcha_token
      }).then((response) => {
        alert('Заявка успешно отправлена!\nНаши менеджеры скоро с Вами свяжутся')
        this.question.phone = ''
        this.errors = {}
      }).catch((error) => {
        this.errors = error.response.data.errors
        this.resetRecaptcha()
      })
    },

    onVerify: function (response) {
        this.captcha_token = response
    },

    onExpired: function () {
        this.captcha_token = ''
    },

    resetRecaptcha () {
      this.$refs.recaptcha.reset() // Direct call reset method
    },
  },

  template:`
    <section class="question-feedback">
        <div class="question-feedback__back">
            <picture>
                <img :data-src='image' class="lazy" alt="">
            </picture>
        </div>
        <div class="question-feedback__container custom-container">
            <div class="question-feedback__layout">
                <div class="question-feedback__content">
                    <h2 class="question-feedback__title">Ответим<br> на любой вопрос</h2>
                    <div class="question-feedback__desc">
                        Возник вопрос или нужен совет по выбору, оставьте заявку и наши консультанты
                        помогут вам
                    </div>

                    <form action="" class="question-feedback__form form-data-inline" @submit.prevent='sendForm'>
                        <label for="question_phone" class="custom-label">Телефон</label>

                        <div class="form-data-inline__parametr form-data-inline__parametr_row_line shadow">
                            <input 
                              v-model='question.phone' 
                              type="text" 
                              id="question_phone" 
                              required="true"
                              name="PHONE_FEEDBACK_QUESTION"
                              class="form-data-inline__input custom-input custom-input_size_xl">
                            <button class="form-data-inline__button button button_theme_yellow button_size_quadro_xl">
                                <svg class="icon icon-send" width="16" height="16">
                                    <use xlink:href='#icon-send'></use>
                                </svg>
                            </button>
                        </div>
                        <label class="custom-checkbox">
                          <vue-recaptcha 
                              sitekey="6LdCUzMcAAAAAFKeD3P-_0tL5YGSvOGf0QOycLH4"
                              ref="recaptcha"
                              @verify="onVerify"
                              @expired="onExpired"></vue-recaptcha>
                        </label>
                        <label class="custom-checkbox">
                            <input type="checkbox" v-model='accept' name="RULE_FEEDBACK_QUESTION" class="custom-checkbox__input">
                            <span class="custom-checkbox__value">
                                <span class="custom-checkbox__icon"></span>
                                Даю свое согласие на <a href="#" target="_blank">обработку персональных данных</a>
                            </span>
                            <span style="color: red; display: block;" v-for="error in errors.base">{{ error }}</span>
                            <span style="color: red; display: block;" v-for="error in errors.phone">Телефон {{ error }}</span>
                        </label>
                    </form>
                </div>

            </div>
        </div>
    </section>
    `

})

